import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/requerimientos/requerimientos';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import RequerimientoCrear from './RequerimientosCrear';
const mstp = state => {
    return {...state.requerimientos}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(RequerimientoCrear)
