// export const renderField = ({



export function estado_update(estado) {
    switch (estado) {
        case 1:
            return "pendiente"
        case 2:
            return "revision";
        case 3:
            return "preparacion";
        case 4:
            return "progreso";
        case 5:
            return "validacion";
        case 6:
        return "finalizado";
      default:
        return estado;
    }
  };

