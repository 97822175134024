import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/mantenimientoR/mantenimientoR";
import Mantenimiento_Reporte from "./Mantenimiento";

const ms2p= (state) => {
    const user = state.login.me;

    return{
        ...state.mantenimientoR,
        user
    };
}

const md2p = {...actions};

export default connect(ms2p, md2p)(Mantenimiento_Reporte)
