import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ProcesosForm';
import ProcesosForm from './ProcesosForm';
import {getMe} from "../../../../../redux/modules/cuenta/login";

export default class ProcesoCrear extends Component {
  state = {
      editar: false,
  };
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.leer(this.props.match.params.id);
          this.setState({editar: true});
      }
  }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data);
    };
    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }
  render() {
    const { crear, editar } = this.props;
    const { updateData, loader } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="Proceso"
              subtitulo="Detalle del proceso"
              col="11"
              >
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          <ProcesosForm
                              onSubmit={this.actualizar}
                              updateData={updateData}
                              editando={true}
                              permisos={this.getPermissions}/>
                          :
                          <Form onSubmit={crear}/>
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
