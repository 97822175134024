import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions, standardActionsMaquinaria} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivoMaquinaria} from "../../../Utils/renderField/renderReadField";
import moment from 'moment';

export default class MaquinariasList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="administracionactivo"
                    titulo="Maquinarias"
                    >
                    <Header to="maquinaria/crear" textBtn="Nueva Maquinaria" enable_search="si" {...this.props}/>
                    <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange}>
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            dataSort
                            width='20%'
                        >
                            C&oacute;digo
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            width='20%'
                        >
                            Nombre
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="fecha_adquisicion"
                            dataSort
                            width='20%'
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                        >
                            Fecha de adquisicion
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="estado"
                            dataSort
                            dataFormat={formatActivoMaquinaria}
                            width='20%'
                        >
                            Estado
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActionsMaquinaria({ editar: "maquinaria", eliminar})}

                        >
                            Acciones
                        </TableHeaderColumn>


                    </Grid>
                </Card>
            </div>
        )
    }
}
