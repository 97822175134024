
import React, { Fragment, useState, Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, FieldArray, formValueSelector, change } from "redux-form";

import TimePicker from 'react-time-picker';
import moment from "moment";
import DatePicker from "react-datepicker";

import "./preparacionform.css"


import {
    renderField,
    renderFieldDisabled,
    renderDatePicker,
    renderTimeField,
    renderDatePickerDisabled,
    renderTextArea,

} from "../../../Utils/renderField/renderField";
import { connect } from "react-redux";

const renderPiezas = ({
    fields,
    meta: { error, submitFailed },
    piezas,
    agregar,
    mensaje,
    submit_estado
}) => (
    
    <div className="col-sm-12 p-0">
        <div className="">
            <button 
                type="button" 
                className="btn btn-primary btn-agregar"
                onClick={() => agregar(fields)}
                disabled={submit_estado ? submit_estado : false}
            >
                Agregar
            </button>
        </div>

        <div className="row col-12 p-0">
            { mensaje && <div className="alerta error_mensaje"> {mensaje} </div> }
        </div>
        
        <div className="col-sm-12 form-group">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center" width="50%">Fecha y hora</th>
                            <th scope="col" className="text-center" width="50%">Cambios realizados</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {/*Contenido de la tabla*/}
            <div className="tabla-con-borde">
                <table className="table table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index} disabled>
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{pointerEvents:"none" }}
                                    >
                                        <Field
                                            name={`${pieza}.fecha`}
                                            component={renderDatePickerDisabled}
                                            width="50%"
                                        />
                                    </td>
                                    
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "50%",pointerEvents:"none"  }}
                                    >
                                        <Field
                                            name={`${pieza}.nombre`}
                                            component={renderFieldDisabled}
                                            options={piezas}
                                            width="50%"
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


let ProgresoForm = props => {

    const {handleSubmit, permisos, editando,submit_estado, cambio, horaCambio, fechaCambio} = props;
    const [mensaje, setMensaje] = useState('');
    const [horaValida, setHoraValida] = useState(false);   

    const validarFormatoHora = (hora) => {        
        const formatoHoraRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        const esFormatoValido = formatoHoraRegex.test(hora);
        setHoraValida(esFormatoValido)
    };

    const agregar=(fields) => {
        //const {cambio, horaCambio, fechaCambio} = this.state;
        if ([cambio, horaCambio, fechaCambio].some(value => value === undefined || value === '')){
            setMensaje('Todos los campos los son obligatorios');
            setTimeout(() => {
                setMensaje('');
            }, 3000);
            return
        }

        validarFormatoHora(horaCambio)

        if (horaValida) {
            setMensaje('Formato de hora no válido')

            setTimeout(() => {
                setMensaje('');
            }, 3000);
            return
        }

        let fecha = moment(fechaCambio).format("YYYY-MM-DD");
        fecha = new Date(fecha+" "+horaCambio)
        fields.push({nombre:cambio, fecha:fecha})

        props.change('cambio', '')
        props.change('hora', '')
        props.change('fecha','')
        
        setHoraValida(false)
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex flex-column col-12  mt-3">
                <div className="row col-md-11 d-flex ml-2">
                    <div className="row col-12 p-0">
                        <div className="col-8 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="material" className="m-0">
                                    Cambios hechos
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                            <Field 
                                name="cambio" 
                                component={renderField} 
                                placeholder="Escriba aqui"  
                                disabled={submit_estado ? submit_estado : false }/>
                                
                            </div>
                        </div>
                        
                        <div className="col-2 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha" className="m-0">
                                    Fecha
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    name="fecha"
                                    component={renderDatePicker}
                                    todayButton="Hoy"
                                />
                            </div>
                        </div>

                        <div className="col-2 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="hora" className="m-0">
                                    Hora
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                

                                <Field
                                    name="hora"
                                    component={renderTimeField} type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0 mt-3">
                        <div className="col-md-12 p-0">
                            <FieldArray
                                name="orden_bitacora_cambio"
                                component={renderPiezas}
                                agregar={agregar}
                                mensaje={mensaje}
                                submit_estado={submit_estado}
                            />
                        </div>
                    </div> 
                </div>

                <div className="col-md-12 p-0">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="observacion_progreso" className="m-0">
                            Observación
                        </label>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <Field name="observacion_progreso" component={renderTextArea} 
                        placeholder="Escriba aqui" rows={4} disabled={submit_estado ? submit_estado : false }/>

                    </div>
                </div>


                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link>
                    {editando ? (
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={permisos()}
                            style={{display: submit_estado ? "none" : ""}}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary">
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
    
};

ProgresoForm = reduxForm({
    form: "OrdenForm",

})(ProgresoForm);


const selector = formValueSelector('OrdenForm');

ProgresoForm = connect(state => {
    let horaCambio = selector(state, 'hora')
    let cambio = selector(state, 'cambio')
    let fechaCambio = selector(state, 'fecha')
    return {
        horaCambio,
        cambio,
        fechaCambio,
    }
})(ProgresoForm)
export default ProgresoForm

