import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { actions } from "../../../../../redux/modules/reportes/mantenimientoR/mantenimientoR";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";


import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import { estado_orden } from "../../../../../utility/constants";

import { api } from 'api';

let listaTipoMantenimientos=[], listaProcesos=[]
const getMantenimientoTipos = (search) => {
    return api.get('mantenimiento_tipo', {search})
        .then((data) => {listaTipoMantenimientos = data ? data.results : []; return listaTipoMantenimientos})
        .catch(() => []);
};


const getProcesos = (search) => {
    return api.get('proceso', {search})
        .then((data) => {listaProcesos = data ? data.results : []; return listaProcesos})
        .catch(() => []);
};

const getEstadoByID = (idEstado) => {
    let estadoselec = null;
    estado_orden.forEach(estado => estado.value === idEstado && (estadoselec = estado));
    return estadoselec
}

const getMantenimientoTipoByID = (idMantenimientoTipo) => {
    let mantenimientoTipoSelec = null;
    listaTipoMantenimientos.forEach(mantenimientoTipo => mantenimientoTipo.id === idMantenimientoTipo && (mantenimientoTipoSelec = mantenimientoTipo));
    return mantenimientoTipoSelec;
};


const getProcesoByID = (idProceso) => {
    let procesoSelec = null;
    listaProcesos.forEach(proceso => proceso.id === idProceso && (procesoSelec = proceso));
    return procesoSelec;
};



export const Filtros = (props) => {

    const {
        generarReporteMantenimiento,
        setSelectFechaFinal,
        setSelectFechaInicial,
        select_fecha_final,
        select_fecha_inicial,
        mantenimiento_tipo_id, 
        filtroMantenimientoTipoChange,
        proceso_id,
        filtroProcesoChange,
        obtener_mantenimiento_excel,
        estado_id, 
        filtroEstadoChange,
        loader

    } = props;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => generarReporteMantenimiento();

    return (
            <div className="row mb-4 ml-1 align-items-center">
                <div className="col-xl-2 col-lg-3 col-md-6 my-2">
                    <div className="col-3 col-lg-12 p-0">
                        <label htmlFor="fecha_inicial">Fecha Inicial</label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <Controller
                            name="fecha_inicial"
                            control={control}
                            defaultValue={select_fecha_inicial}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Inicial"
                                    onChange={(value) => {
                                        setSelectFechaInicial(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    // value={select_fecha_inicial}
                                    selected={select_fecha_inicial}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                       
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <ErrorMessage
                            errors={errors}
                            name="fecha_inicial"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-6 my-2 form-group">
                    <div className="col-3 col-lg-12 p-0">
                        <label htmlFor="fecha_final">Fecha Final</label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <Controller
                            name="fecha_final"
                            control={control}
                            defaultValue={select_fecha_final}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Final"
                                    onChange={(value) => {
                                        setSelectFechaFinal(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    // value={select_fecha_final}
                                    selected={select_fecha_final}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fecha_final"
                            render={({ message }) => (
                                <p className="text-danger ">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                    <div className="col-12 col-lg-12 p-0">
                        <label className="my-1 mr-2 pl-0">Tipo de mantenimiento</label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <AsyncSelect
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            isClearable
                            placeholder="Seleccionar"
                            cacheOptions
                            defaultOptions
                            loadOptions={getMantenimientoTipos}
                            onChange={(e) => {
                                filtroMantenimientoTipoChange(e ? e.id : -1);
                                //onChange(e);
                            }}
                            // onBlur={onBlur}
                            value={getMantenimientoTipoByID(mantenimiento_tipo_id)}
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['nombre'])}
                        />
                    </div>
                    
                </div>

                <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                    <div className="col-3 col-lg-12 p-0">
                        <label className="my-1 mr-2 pl-0">Proceso</label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <Controller
                            name="proceso"
                            control={control}
                            defaultValue={proceso_id}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <AsyncSelect
                                    className={classNames('react-select-container')}
                                    backspaceRemovesValue={false}
                                    isSearchable={true}
                                    isClearable
                                    placeholder="Seleccionar"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={getProcesos}
                                    onChange={(e) => {
                                        filtroProcesoChange(e ? e.id : -1);
                                        onChange(e);
                                    }}
                                    onBlur={onBlur}
                                    value={getProcesoByID(proceso_id)}
                                    getOptionValue={(option) => (option['id'])}
                                    getOptionLabel={(option) => (option['nombre'])}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                    <div className="col-12 col-lg-12 p-0">
                        <label className="my-1 mr-2 pl-0">Estado </label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <AsyncSelect
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            isClearable
                            placeholder="Seleccionar"
                            cacheOptions
                            defaultOptions={estado_orden}
                            onChange={(e) => {
                                filtroEstadoChange(e ? e.value : -1);
                            }}
                            value={getEstadoByID(estado_id)}
                            getOptionValue={(option) => (option['value'])}
                            getOptionLabel={(option) => (option['label'])}
                        />
                    </div>
                    
                </div>
                    

                <div className="col-xl-2 my-2 p-0 d-flex">
                    <div className='col-lg-12 mt-4 ml-2'>
                        <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={async () => {
                                try {
                                    await generarReporteMantenimiento();
                                } catch (error) {
                                    console.error('Error generating report:', error);
                                }
                            }}
                        >
                            Buscar
                        </button>

                        <button type="button" className="btn" onClick={async () => {
                            try {
                                await obtener_mantenimiento_excel();
                            } catch (error) {
                                console.error('Error exporting data:', error);
                            }
                        }} disabled={loader}>Descargar</button>
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.mantenimientoR,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
