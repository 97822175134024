import { api } from "api";
import React, { Component } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import { Link } from "react-router-dom";

const logo = require("assets/img/logo.png");
const defaultAvatar = require("assets/img/icons/vector_usuario.png");
const vector = require("assets/img/icons/vector.png");

const perfil = require("assets/img/icons/perfil.png");
const cerrar_sesion = require("assets/img/icons/cerrar_session.png");

let listaProveedores = [];

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {
        const { navToggle, logOut, user } = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                <div className="main-navbar__search w-100 justify-content-between">
                    <div> </div>
                    <div className="justify-content-center">
                        <img
                            src={logo}
                            width="240px"
                            height="63px"
                            alt=""
                            className="ml-5"
                        />
                    </div>

                    <div className="d-lg-flex justify-content-end p-4">
                    <ul className="flex-row navbar-nav mr-2 col-lg-12">
                    <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                    >
                        <DropdownToggle
                            color="light"
                            style={{
                                display :"flex",
                                width: "180px",
                                alignItems: "center",
                                border: "2px solid #606060",
                                backgroundColor: "transparent",
                            }}
                            className="d-flex justify-content-between align-items-center"
                        >
                                <div className="d-flex align-items-center">
                                    <img
                                        className="mr-2"
                                        src={
                                            user && user.avatar
                                                ? user.avatar
                                                : defaultAvatar
                                        }
                                        alt="User"
                                    />
                                    <span style={{
                                        color: "#606060",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                    }}
                                    className="d-none d-md-inline-block mr-3">
                                        {user.username}
                                    </span>
                                </div>
                                <span className="d-none d-md-inline-block">
                                    <img src={vector}/>
                                </span>
                           
                            
                        </DropdownToggle>
                        <DropdownMenu
                            style={{
                                width: "177px"
                            }}
                            >
                            <div className="br-top"></div>
                            <Link
                                tabIndex="0"
                                to="/user-profile"
                                className="no-underline"
                            >
                                <DropdownItem>PERFIL</DropdownItem>
                            </Link>
                            <DropdownItem onClick={logOut}>
                                <a tabIndex="0" href="/">
                                    CERRAR SESIÓN
                                </a>
                            </DropdownItem>
                            <div className="br-top"></div>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;
