import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/kardex";
import Kardex_Reporte from "./kardex";

const ms2p= (state) => {
    const user = state.login.me;

    return{
        ...state.kardex,
        user
    };
}

const md2p = {...actions};

export default connect(ms2p, md2p)(Kardex_Reporte)
