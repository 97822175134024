import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './acciones.css';
import Swal from "sweetalert2";


const ver_img = require("assets/img/icons/ver.png");
const editar_img = require("assets/img/icons/editar.png");
const alerta = require("assets/img/icons/alerta_roja.png");
const eliminar_orden = require("assets/img/icons/ordendelete.png");
const accion = require("assets/img/icons/ordenaccion.png");
class Acciones extends Component {
    constructor(props) {
        super(props);
        this.editar = this.editar.bind(this);
        this.ver = this.ver.bind(this);
        this.setTabEdit = this.setTabEdit.bind(this);
    }

    eliminar = (id) => {
        return () => {
            const SwalMod = Swal.mixin({
                customClass: {
                    confirmButton: "btn-swal btn-primary-swal",
                    cancelButton: "btn btn-outline-dark mr-5",
                    //   content: 'd-flex justify-content-center mb-4',
                    //   title: 'verde-secundario',
                    //   image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            });
            SwalMod.fire({
                title: "¿ELIMINAR?",
                text: "Esta acción no se puede revertir",
                imageUrl: alerta,
                imageAlt: "Alerta",
                showCancelButton: true,
                confirmButtonText: "ELIMINAR",
                cancelButtonText: "CANCELAR",
                reverseButtons: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    editar(id, row) {
        return () => {
            this.props.editar(id, row);
        };
    }

    ver(id, row) {
        return () => {
            this.props.ver(id, row);
        };
    }

    updateEstado = (id, estado, tipo) => {
        return () => {
            if(estado ==1 && tipo !==3){
                estado = "En preparacion"
            }

            if(estado ==1 && tipo == 3){
                estado = "En revision"
            }

            if(estado == 2){
                estado = "En preparacion"
            }

            if(estado == 3){
                estado = "En progreso"
            }

            if(estado == 4){
                estado = "En validacion"
            }

            if(estado == 5){
                estado = "Finalizado"
            }

            const SwalMod = Swal.mixin({
                customClass: {
                    confirmButton: "btn-swal btn-primary",
                    cancelButton: "btn btn-outline-dark mr-5",
                    //   content: 'd-flex justify-content-center mb-4',
                    //   title: 'verde-secundario',
                    //   image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            });
            SwalMod.fire({
                title: `¿Actualizar estado a ${estado}?`,
                text: "Esta acción no se puede revertir",
                imageUrl: alerta,
                imageAlt: "Alerta",
                showCancelButton: true,
                confirmButtonText: "ACEPTAR",
                cancelButtonText: "CANCELAR",
                reverseButtons: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.value) {
                    this.props.updateEstado(id);
                }
            });
        };
    };


    estado_update(estado){
        switch (estado) {
            case 1:
                return "pendiente"
            case 2:
                return "revision";
            case 3:
                return "preparacion";
            case 4:
                return "progreso";
            case 5:
                return "validacion";
            case 6:
            return "finalizado";
          default:
            return estado;
        }
    }

    setTabEdit = (id,tab) => {
        return () => {
            this.props.setTabEdit(id,tab)
        };
    }


    render() {
        const { id, row, ver, eliminar, updateEstado,editar, user} = this.props;
        let orden_estado = null;
        let orden_validar = null;
        if (user.rol_usuario) {
            orden_estado = (user && user.rol_usuario.orden_estado);
            orden_validar = (user && user.rol_usuario.orden_validar);
        }
        else{
            orden_estado = true;
            orden_validar = true;
        }

        return (

            <div className="d-flex justify-content-center">
                {ver !== undefined && typeof ver === "string" && row.estado !==6 &&(
                    
                    <Link
                        onClick={this.setTabEdit(id,this.estado_update(row.estado)) }
                        //to={`${ver}/${id}/`}
                        to={`${ver}/${this.estado_update(row.estado)}/${id}`} 
                        className=""
                    >
                        <img src={ver_img} alt="Ver" className="action_img" />
                    </Link>
                )}

                {ver !== undefined && typeof ver === "string" && row.estado ===6 &&(
                    <Link
                        onClick={this.setTabEdit(id,"pendiente") }
                        to={`${ver}/finalizado/${id}/`} className=""
                    >
                        <img src={ver_img} alt="Ver" className="action_img" />
                    </Link>
                )}
                
                {orden_estado && updateEstado !== undefined && row.estado !==5 && row.estado !==6 &&(
                    <a
                        className=""
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.updateEstado(id, row.estado, row.mantenimiento_tipo.id)}
                    >
                        <img
                            src={accion}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}

                {orden_validar && updateEstado !== undefined && row.estado ===5 && (
                    <a
                        className=""
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.updateEstado(id, row.estado, row.mantenimiento_tipo.id)}
                    >
                        <img
                            src={accion}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}

                {/* Opciones de edidcion */}
                {/* orden_estado && */}

                

                {row.estado ===1 && (
                    <Link
                        className="text-warning"
                        onClick={this.setTabEdit(id,this.estado_update(row.estado)) }
                        to={`${editar}/${this.estado_update(row.estado)}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )}

                {/* Solo el solicitante puede cambiar la orden */}
                {user.rol_usuario.nombre_rol === "Solicitante" && row.estado ===5 && (
                    <Link
                        className="text-warning"
                        onClick={this.setTabEdit(id,this.estado_update(row.estado)) }
                        to={`${editar}/${this.estado_update(row.estado)}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )}




                {editar !== undefined  && typeof editar === "string" && row.estado !==6 && row.estado !==1 
                && user.rol_usuario.nombre_rol !== "Solicitante" && row.estado !==5 &&(
                    <Link
                        className="text-warning"
                        onClick={this.setTabEdit(id,this.estado_update(row.estado)) }
                        to={`${editar}/${this.estado_update(row.estado)}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )}




                {/* {orden_validar && editar !== undefined  && typeof editar === "string" && row.estado ===5 && (
                    <Link
                        className="text-warning"
                        onClick={this.setTabEdit(id,this.estado_update(row.estado)) }
                        to={`${editar}/${this.estado_update(row.estado)}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )} */}
                
               

                {eliminar !== undefined && row.estado === 1 &&(
                    <a
                        className=""
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminar(id)}
                    >
                        <img
                            src={eliminar_orden}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}
                
            </div>
        );
    }
}

Acciones.propTypes = {};

export function OrdenActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} row={row} {...acciones} />;
    };
}

