import React from "react";
import CardEmpty from "../../../Utils/Cards/CardEmpty"
import LoadMask from "../../../Utils/LoadMask/LoadMask"
import Card from "../../../Utils/Cards/Card";
import Filtros from "./filtro";
import TablaMantenimiento from "./TablaMantenimiento";
import "./card.css"
const Mantenimiento_Reporte = (props) => {
  
  const {loader, data} = props;


  return(
    <Card
      icon="reportesactivo"
      titulo="Reporte de mantenimientos"
    >
      <LoadMask loading={loader} blur>
        <Filtros {...props}/>
		<h5 className="ml-3"> Totales de mantenimientos</h5>
	
		<div className="col-12 col-md-12 mt-4">
			<CardEmpty noShadow>
				<div className="col-12 pr-4 pl-4">
					<div className="d-flex justify-content-between align-items-center p-3">
						<div className="d-flex flex-column align-items-center">
							<span className="gris mr-3">Registrados</span>
							<h3 className="mb-1 ml-2 gris"> {data ? data.count: 0}</h3>
						</div>

						<div className="d-flex flex-column align-items-center">
							<span className="gris mr-3">Finalizados</span>
							<h3 className="mb-1 ml-2 gris"> {data ? data.finalizado: 0}</h3>
						</div>

						<div className="d-flex flex-column align-items-center">
							<span className="gris mr-3">Registrados Preventivos</span>
							<h3 className="mb-1 ml-2 gris"> {data ? data.preventivo: 0}</h3>
						</div>

						<div className="d-flex flex-column align-items-center">
							<span className="gris mr-3">Registrados Correctivos</span>
							<h3 className="mb-1 ml-2 gris"> {data ? data.correctivo: 0}</h3>
						</div>
						
					</div>
				</div>
			</CardEmpty>
		</div>

        <CardEmpty>
          <h5>Listado de mantenimientos</h5>
          <TablaMantenimiento {...props}/>
        </CardEmpty>
          
      </LoadMask>
    </Card>
  )
}

export default Mantenimiento_Reporte;