import { api } from "api";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderTextArea,
    AsyncSelectField,
    renderDatePicker,
    AsyncSelectFieldTable

} from "../../../Utils/renderField/renderField";
import moment from "moment";
const getProcesos = (search) => {
    return api
        .get("proceso", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getPrioridad = (search) => {
    return api
        .get("prioridad", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getMaquinaria = (search) => {
    return api
        .get("maquinaria", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getEncargado = (search) => {
    const params ={}
    params.search = search;
    return api
        .get('user/get_encargado', params )
        .then((data) => {
            if (data) return data;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getRequerimiento = (search) => {
    return api
        .get("requerimiento", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};


// api.get('orden/get_ordenes', params).then((response) => {
//     dispatch(setData(response));
//     dispatch(setPage(page));
// }).catch(() => {
// }).finally(() => {
//     dispatch(setLoader(false));
// });





const getMantenimientoTipo = (search) => {
    return api
        .get("mantenimiento_tipo", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};


const getEncargadoCodigo = async (id) => {
    try {
        const response = await api.get('user/get_codigo_encargado', {id});
        return response;
    } catch (error) {
        return [];
    }
}




let OrdenForm = (props) => {
    const { handleSubmit, permisos, editando, submit_estado,item, user,loader} = props;

 
    const [codigoEncargado, setCodigoEncargado] = useState("")
    const [encargado, setEncargado] = useState(null)

    const handleEncargadoChange = async (dato) => {
        const datan = await getEncargadoCodigo(dato.id)
        setEncargado(dato)
        setCodigoEncargado(datan.codigo_empleado)
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-12 p-0">
                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_solicitud" className="m-0">
                                Fecha creacion
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_solicitud"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_ejecucion" className="m-0">
                                Fecha inicio
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_ejecucion"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                placeholder="DD/MM/AAAA"
                                // disabled={
                                //     (user.rol_usuario.nombre_rol === 'Administrador' && submit_estado === false) 
                                //     ? false : (user.rol_usuario.nombre_rol === 'Administrador' && submit_estado === true) 
                                //     ? true : false
                                // }

                                disabled={
                                    submit_estado ? submit_estado : 
                                    ((editando ? (user.rol_usuario.nombre_rol === 'Administrador' ? false: true ) : true) )

                                }
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_finalizacion" className="m-0">
                                Fecha Final
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_finalizacion"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                placeholder="DD/MM/AAAA"
                                // disabled={
                                //     (user.rol_usuario.nombre_rol === 'Administrador' && submit_estado === false) 
                                //     ? false : (user.rol_usuario.nombre_rol === 'Administrador' && submit_estado === true) 
                                //     ? true : false
                                // }

                                disabled={
                                    submit_estado ? submit_estado : 
                                    ((editando ? (user.rol_usuario.nombre_rol === 'Administrador' ? false: true ) : true) )
                                }
                            />
                        </div>
                    </div>

                    
                </div>

                <div className="row col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="proceso" className="m-0">
                                Proceso solicitante
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="proceso"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getProcesos}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ? true : false) 
                                }
                            />
                        </div>
                    </div>

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="proceso_solicitado" className="m-0">
                                Proceso solicitado
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="proceso_solicitado"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getProcesos}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ? true : false) 
                                }
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="maquinaria" className="m-0">
                                Maquinaria - equipo
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="maquinaria"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getMaquinaria}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ? true : false) 
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="prioridad" className="m-0">
                                Prioridad
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="prioridad"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPrioridad}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ?  true : false) 
                                }
                            />
                        </div>
                    </div>

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="requerimiento" className="m-0">
                                Requerimiento
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="requerimiento"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getRequerimiento}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ? true : false) 
                                }
                            />
                        </div>
                    </div>
                   
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mantenimiento_tipo" className="m-0">
                                Tipo mantenimiento
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="mantenimiento_tipo"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getMantenimientoTipo}
                                className="form-control"
                                disabled={
                                    submit_estado ? submit_estado : (editando ?  true : false) 
                                }
                            />
                        </div>
                    </div>
                </div>

                {editando && (
                    <div className="row col-md-12 p-0" >
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="encargado" className="m-0">
                                    Encargado de orden
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="encargado_orden"
                                    labelKey="nombre_completo"
                                    label="nombre_completo"
                                    component={AsyncSelectFieldTable}
                                    loadOptions={getEncargado}
                                    className="form-control"

                                    valor={encargado ? encargado: item ? item.encargado_orden: null}
                                    value={encargado ? encargado: item ? item.encargado_orden: null}

                                    // disabled={
                                    //     submit_estado ? submit_estado : (editando ? true : false) 
                                    // }

                                    disabled={
                                        submit_estado ? submit_estado : 
                                        ((editando ? (user.rol_usuario.nombre_rol === 'Administrador' ? false: true ) : true) )
    
                                    }

                                    handleChange = {handleEncargadoChange}
                                />

                            </div>
                        </div>

                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="codigo_encargado_orden" className="m-0">
                                    Código de encargado
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    name="codigo_encargado_orden"
                                    component={({ input }) => (
                                    <input
                                        className="form-control"
                                        disabled
                                        value={codigoEncargado ? codigoEncargado : item ? item.codigo_encargado_orden : ""}
                                        onChange={(codigoEncargado)=>{
                                            input.onChange(codigoEncargado)
                                        }}
                                    />
                                    )}
                                />
                            </div>
                        </div>
                    </div>       
                ) }
                

                <div className="row col-md-12 p-0">
                    <div className="col-4 mt-3">
                        <label htmlFor="descripcion">Descripcion del mantenimiento</label>
                        <Field name="descripcion" component={renderTextArea} placeholder="escriba aqui" rows={4} 
                            disabled={
                                submit_estado ? submit_estado : (editando ?  true : false )
                            }
                        />
                    </div>

                    <div className="col-4 mt-3">
                        <label htmlFor="observacion_adicional">Observaciones adicionales</label>
                        <Field name="observacion_adicional" component={renderTextArea} placeholder="escriba aqui" rows={4} 
                            disabled={
                                submit_estado ? submit_estado : ( editando ? true : false )
                            }
                        />
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link>
                    {editando ? (
                        
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={permisos()}
                            style={{display: submit_estado ? "none" : ""}}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary" disabled={loader}>
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};
OrdenForm = reduxForm({
    form: "OrdenForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido."),
            descripcion: validators.exists()("Campo requerido."),
            prioridad:validators.exists()("Campo requerido."),
            proceso:validators.exists()("Campo requerido."),
            maquinaria:validators.exists()("Campo requerido."),
            // encargado_orden:validators.exists()("Campo requerido."),
            mantenimiento_tipo:validators.exists()("Campo requerido."),
            // fecha_ejecucion:validators.exists()("Campo requerido."),
            requerimiento: validators.exists()("Campo requerido."),
            // fecha_finalizacion: validators.exists()("Campo requerido."),
        });
    },
    initialValues: {
        fecha_solicitud: moment(),
    }
})(OrdenForm);

export default OrdenForm;
