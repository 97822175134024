import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderFilePicker,
    SelectField,
    renderNumber,
} from "../../Utils/renderField/renderField";

const genders = [
    { label: "Masculino", value: 0 },
    { label: "Femenino", value: 1 },
];

const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar } = props;
    return (
        <Fragment>
            <div
                className="d-flex align-items-baseline ml-3"
                style={{
                    position: "relative",
                    borderBottom: "2px solid #359D9E",
                }}
            >
                <img src={require(`assets/img/icons/perfil_titulo.png`)} alt="Usuario" className="title_img mr-2"/>
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                <h2 className="mr-2 titulo">Mi perfil</h2>
                
            </div>

            <div className="mb-4 card-tabla">
                <div className="card-content-form">
                    <div className="col-12 col-lg-8">
                        <form action="" onSubmit={handleSubmit} className="">
                            <div className="form-group grid-container">
                                <div className="form-group grid-container d-flex align-items-center flex-column">
                                    <div className="row col-md-12 p-0">
                                        <div className="col-md-6 ">
                                            <div className="col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="nombre"
                                                    className="m-0"
                                                >
                                                    Nombre
                                                </label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <Field
                                                    name="nombre"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="apellido"
                                                    className="m-0"
                                                >
                                                    Apellido
                                                </label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <Field
                                                    name="apellido"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row col-md-12 p-0">
                                        <div className="col-md-6 ">
                                            <div className="col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="username"
                                                    className="m-0"
                                                >
                                                    Nombre de usuario
                                                </label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <Field
                                                    name="username"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="email"
                                                    className="m-0"
                                                >
                                                    Correo electrónico
                                                </label>
                                            </div>
                                            <div className="col-md-12  form-group">
                                                <Field
                                                    name="email"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row col-md-12 p-0">
                                        <div className="col-md-6">
                                            <div className="col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="new_password"
                                                    className="m-0"
                                                >
                                                    Contraseña (nueva)
                                                </label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <Field
                                                    name="new_password"
                                                    placeholder=""
                                                    component={renderField}
                                                    type="password"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <button className="btn btn-primary mx-auto mb-3">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="border-bottom card-header">
                        <h6 className="m-0">
                            {me.first_name} {me.last_name}
                        </h6>
                    </div>
                    {/* card-content-form */}
                    {/* <div className="form-group has-feedback flex-1 mx-3">
                            <label htmlFor="avatar">Avatar</label>
                            <Field photo={me.profile && me.profile.avatar ? me.profile.avatar : null} setFile={setAvatar} name="avatar" component={renderFilePicker} />
                        </div> */}
                </div>
            </div>
        </Fragment>
    );
};

export default reduxForm({
    form: "profile", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()("Campo requerido."),
            nombre: validators.exists()("Campo requerido."),
            apellido: validators.exists()("Campo requerido."),
            email: validators.exists()("Campo requerido."),

        });
    },
})(ProfileForm);
