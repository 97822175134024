import { createAction, handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import { endsWith } from 'lodash';
import RevisionForm from '../../../common/components/App/Ordenes/Crear/RevisionForm';
import PreparacionForm from '../../../common/components/App/Ordenes/Crear/PreparacionForm';
import { isNull } from "lodash";


// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_COMPRA';
const DATA = 'DATA_ORDEN';
const ITEM_DATA = 'ITEM_ORDEN';
const PAGE = 'PAGE_ORDEN';
const ORDERING = 'ORDERING_ORDEN';
const SEARCH = 'SEARCH_ORDEN';

const FECHA_INICIAL = 'FECHA_INICIAL';
const FECHA_FINAL = 'FECHA_FINAL';
const TAB_ORDENES = 'TAB_ORDENES';

const TAB_ORDENES_CREATE = 'TAB_ORDENES_CREATE';
const ESTADO = "ESTADO"


const FILTRO_PROCESO_ORDENES = "FILTRO_PROCESO_ORDENES";
const FILTRO_TIPO_MANTENIMIENTO_ORDENES ="FILTRO_TIPO_MANTENIMIENTO_ORDENES";



// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setProceso = proceso => ({
    type: FILTRO_PROCESO_ORDENES,
    proceso,
})

// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1, estado=false) => (dispatch, getStore) => {
    const resource = getStore().ordenes;

    const params = { page };

    if (resource.fecha_inicio !== null)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD") + " 00:00:00";

    if (resource.fecha_final !== null)
        params.fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD") + " 23:59:59";   // Para incluir cualquier hora del día

    params.ordering = resource.ordering;
    params.search = resource.search;


    if(!estado)
        dispatch(setTab("TODAS"))

    if(estado)
        params.estado = estado

    if(resource.mantenimiento_tipo >0)
        params.mantenimiento_tipo = resource.mantenimiento_tipo

    if(JSON.stringify(resource.proceso) !== '{}')
        params.proceso = resource.proceso.id

    if(resource.proceso > 0)
        params.proceso = resource.proceso


    dispatch(setLoader(true));

    api.get('orden/get_ordenes', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//Obtener los datos para la edicion cuando sea diferente a pendiente
const listar_preparacion = (id, estado) => (dispatch, getStore) => {
    
    let endpoint = "get_"+estado;
    
    dispatch(setLoader(true));
    const params = {};
    params.id_orden = id;
    
    api.get(`orden/${endpoint}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));
        dispatch(initializeForm("OrdenDetalle", response));
        dispatch(setData(response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer =(id, estado=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`orden/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('orden', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/ordenes"));
    }).catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la transacción', 'ERROR'); }
        else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {

    dispatch(setLoader(true));
    api.put(`orden/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/ordenes"));

    }).catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la transacción', 'ERROR'); }
        else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`orden/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


// Actualiza
// Revision
// Preparacion
// Validacion

const editar_revision = (id,estado,data) => (dispatch) => {
    let endpoint = "";
    endpoint = estado+"_orden";

    dispatch(setLoader(true));
    api.put(`orden/${id}/${endpoint}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/ordenes"));  

    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



const updateEstado = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`orden/${id}/estado_update`).then(() => {
        dispatch(listar());
        NotificationManager.success('Estado actualizado', 'Éxito', 3000);
    }).catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la transacción', 'ERROR'); }
        else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



const clearItem = () => dispatch => {
    // dispatch(setItem({}));
    // dispatch(initializeForm("OrdenForm", ""));

    dispatch(initializeForm('OrdenForm', {
        fecha_solicitud: moment(),
    }));
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

///// Filtros
const fechaFinal = fecha_final => (dispatch) => {
    dispatch({ type: FECHA_FINAL, fecha_final })
    //dispatch(listar())
}

const fechaInicio = fecha_inicio => (dispatch) => {
    dispatch({type: FECHA_INICIAL, fecha_inicio});
};

const filtroProcesoChange = proceso => (dispatch) => {
    dispatch(setProceso(proceso))
}

const filtroMantenimientoTipoChange = mantenimiento_tipo => (dispatch) => {
    dispatch({type: FILTRO_TIPO_MANTENIMIENTO_ORDENES, mantenimiento_tipo})
}






/// Tabs
const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_ORDENES, tab});
    dispatch(listar(1, tab))

}

///______ Tab en el formulario de editar las ordenes
const setTabEdit = (id,tab_create) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_ORDENES_CREATE, tab_create})

    if (tab_create== "pendiente"){
        dispatch(leer(id,tab_create))
        return
    }
    
    dispatch(listar_preparacion(id,tab_create))

}


export const actions = {
    listar,
    listar_preparacion,
    leer,
    crear,
    editar,
    eliminar,
    updateEstado,
    editar_revision,
    searchChange,
    onSortChange,
    fechaFinal,
    fechaInicio,
    setTab,
    setTabEdit,
    filtroProcesoChange,
    filtroMantenimientoTipoChange,
    clearItem
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FECHA_INICIAL]: (state, {fecha_inicio}) => {
        return {
            ...state,
            fecha_inicio,
        };
    },
    [FECHA_FINAL]: (state, {fecha_final}) => {
        return {
            ...state,
            fecha_final,
        };
    },

    [FILTRO_PROCESO_ORDENES]: (state, {proceso}) => {
        return {
            ...state,
            proceso,
        }
    },
    [FILTRO_TIPO_MANTENIMIENTO_ORDENES]: (state, {mantenimiento_tipo}) => {
        return {
            ...state,
            mantenimiento_tipo
        }
    },

    [TAB_ORDENES]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },

    [TAB_ORDENES_CREATE]: (state, {tab_create}) => {
        return {
            ...state,
            tab_create
        }
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    fecha_inicio: null,
    fecha_final: null,
    // proceso_id: -1,
    proceso:{},
    mantenimiento_tipo: -1,
    tab:"TODAS",
    tab_create:""
};

export default handleActions(reducers, initialState)


