import React from "react";
import NumberFormat from "react-number-format";

export const RenderNumber = ({ value, decimalScale, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix=""
            displayType="text"
        />
    );
};

export const RenderCurrency = ({ value, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix="Q "
            displayType="text"
        />
    );
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};

export const formatActivoMaquinaria = (cell) => {
    if (cell == 1) {
        return "Libre";
    }
    if ((cell = 2)) {
        return "En Mantenimiento";
    }
};

export const formatActivoOrden = (cell) => {
    if (cell == 1) {
        return <button className="tab-pendiente btn-tabs"> Pendiente</button>;
        return "Pendiente";
    }
    if ((cell == 2)) {
        return <button className="tab-revision btn-tabs"> En revisión</button>;
        return "En revisión";
    }
    if ((cell == 3)) {
        return <button className="tab-preparacion btn-tabs"> En preparación</button>;
        return "En preparación";
    }
    if ((cell == 4)) {
        return <button className="tab-progreso btn-tabs"> En progreso</button>;
        return "En progreso";
    }
    if ((cell == 5)) {
        return <button className="tab-validacion btn-tabs"> En validación</button>;
        return "En validación";
    }

    if ((cell == 6))
    {
        return <button className="tab-finalizado btn-tabs"> Finalizado</button>;
        return "Finalizado"
    }
};

export const MantenimientoProgramado = (cell) => {
    if (cell){
        return "Si"
    }

    return "No"

}


export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};
