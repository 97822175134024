import React, { Component } from "react";
import Tabs, {TabPane} from "rc-tabs";

import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";

import estado_update from "../../../Utils/Estado/Orden"

export default class OrdenTab extends Component {
    render() {
        const { setTabEdit, tab_create, estadoDetalle } = this.props;
        
        const {estado, id_orden} = this.props.state
        
        return (
            
            <Tabs
                activeKey={(tab_create == "" ? estado ? estado: estadoDetalle: tab_create)}
                // tabBarPosition="top"
                onChange={(tab_create) => {setTabEdit(id_orden,tab_create);}}
                renderTabBar={() => <TabBar />}
                renderTabContent={() => <TabContent />}
                className={"mt-3 mb-3 p-3"}
            >
                <TabPane
                    tab="General"
                    key="pendiente"
                    style={{ width: "16%", pointerEvents: "none" }}
                ></TabPane>
                <TabPane
                    tab="Revisión"
                    key="revision"
                    style={{ width: "16%", pointerEvents: "none" }}
                ></TabPane>
                <TabPane
                    tab="Preparación"
                    key="preparacion"
                    style={{ width: "16%", pointerEvents: "none" }}
                ></TabPane>
                <TabPane
                    tab="Progreso"
                    key="progreso"
                    style={{ width: "16%", pointerEvents: "none" }}
                ></TabPane>
                <TabPane
                    tab="Validación"
                    key="validacion"
                    style={{ width: "16%", pointerEvents: "none" }}
                ></TabPane>
            </Tabs>
        );
    }
}
