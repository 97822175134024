import React from "react";
import CardEmpty from "../../../Utils/Cards/CardEmpty"
import LoadMask from "../../../Utils/LoadMask/LoadMask"
import Card from "../../../Utils/Cards/Card";
import Filtros from "./filtro";
import TablaEquipo from "./TablaEquipo";

export const Equipo_Reporte = (props) => {
    const {loader, data} = props;

    return(
        <Card
        icon="reportesactivo"
        titulo="Reporte de Equipos"
        >
        <LoadMask loading={loader} blur>
            <Filtros {...props}/> 
            

            <CardEmpty>
            <h5>Listado de mantenimientos</h5>
            <TablaEquipo {...props}/>
            </CardEmpty>
            
        </LoadMask>
        </Card>
    )
}
