import { handleActions } from "redux-actions";
import { api } from "api";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const OBTENER_LISTA = "OBTENER_LISTA_REPORTE_KARDEX";
const LOADER = "LOADER_REPORTE_KARDEX";
const SET_PAGE = "SET_PAGE_REPORTE_KARDEX";
const SET_LOADER_EXCEL = "SET_LOADER_EXCEL_REPORTE_KARDEX";
const SET_PARAMS_USED = "SET_PARAMS_USED_REPORTE_KARDEX";
const SELECT_FECHA_INICIAL = "SELECT_FECHA_INICIAL_REPORTE_KARDEX";
const SELECT_FECHA_FINAL = "SELECT_FECHA_FINAL_REPORTE_KARDEX";

const FILTRO_TIPO_MANTENIMIENTO_ORDENES ="FILTRO_TIPO_MANTENIMIENTO_ORDENES";

const FILTRO_MAQUINARIA = "FILTRO_MAQUINARIA";

const TAB_ORDENES_CREATE = 'TAB_ORDENES_CREATE';

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setParamsUsed = (params_used) => ({
    type: SET_PARAMS_USED,
    params_used,
});

const setLoaderExcel = (loader_excel) => ({
    type: SET_LOADER_EXCEL,
    loader_excel,
});

const setData = (data) => ({
    type: OBTENER_LISTA,
    data,
});

const setSelectFechaInicial = (select_fecha_inicial) => ({
    type: SELECT_FECHA_INICIAL,
    select_fecha_inicial,
});

const setSelectFechaFinal = (select_fecha_final) => ({
    type: SELECT_FECHA_FINAL,
    select_fecha_final,
});
const setPage = (page) => ({
    type: SET_PAGE,
    page,
});


const filtroMantenimientoTipoChange = mantenimiento_tipo_id => (dispatch) => {
    dispatch({type: FILTRO_TIPO_MANTENIMIENTO_ORDENES, mantenimiento_tipo_id})
}


const filtroMaquinariaChange = maquinaria_id => (dispatch) => {
    dispatch({type: FILTRO_MAQUINARIA, maquinaria_id})
}




export const generarReporteKardex = (page) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    //Tomamos la tada del filtro para pasarsela a los params y obtener la primera pagina
    const { select_fecha_inicial, select_fecha_final, mantenimiento_tipo_id, maquinaria_id } =
        getStore().kardex;
    const params = {};
    const params_used = {};

    // para las fechas validamos el objeto Moment
    if (select_fecha_inicial != null) {
        params.fecha_inicial = select_fecha_inicial.format("YYYY-MM-DD");
    }
    if (select_fecha_final != null) {
        params.fecha_final = select_fecha_final.format("YYYY-MM-DD");
    }

    if(mantenimiento_tipo_id > 0){
        params.mantenimiento_tipo = mantenimiento_tipo_id
    }

    if(maquinaria_id > 0){
        params.maquinaria = maquinaria_id
    }

    params["page"] = page || 1;
    api.get("maquinaria/reporte_maquinaria", params)
        .then((response) => {
            dispatch(setData(response));
            if (page) {
                dispatch(setPage(page));
            } else {
                dispatch(setPage(1));
            }
            dispatch(setParamsUsed(params_used));
        })
        .catch((e) => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


//Obtener los datos para la edicion cuando sea diferente a pendiente
const listar_preparacion_d = (id, estado) => (dispatch, getStore) => {
    let endpoint = "get_"+estado;
    
    dispatch(setLoader(true));
    const params = {};
    params.id_orden = id;
    
    api.get(`orden/${endpoint}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));
        dispatch(initializeForm("OrdenDetalle", response));
        dispatch(setData(response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const leer =(id, estado=false) => (dispatch) => {
    dispatch(setLoader(true));
    
    api.get(`orden/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

///______ Tab en el formulario de editar las ordenes
const setTabEdit = (id,tab_create) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_ORDENES_CREATE, tab_create})
    if (tab_create== "pendiente"){
        dispatch(leer(id,tab_create))
        return
    }

    dispatch(listar_preparacion_d(id,tab_create))

}

export const obtener_kardex_excel = () =>  (dispatch, getStore) => {
    const resource = getStore().kardex;

    let endpoint = `/api/maquinaria/descargar_reporte_kardex/?auth_token=${localStorage.getItem("token")}`;

    dispatch(generarReporteKardex())

    if (resource.select_fecha_inicial) {
        endpoint += `&fecha_inicial=${resource.select_fecha_inicial.format("YYYY-MM-DD")}`;
    }

    if (resource.select_fecha_final) {
        endpoint += `&fecha_final=${resource.select_fecha_final.format("YYYY-MM-DD")}`;
    }

    if (resource.mantenimiento_tipo_id > 0) {
        let mantenimiento_tipo = resource.mantenimiento_tipo_id
        endpoint += `&mantenimiento_tipo=${[mantenimiento_tipo]}`;
    }

    if (resource.maquinaria_id > 0) {
        let maquinaria = resource.maquinaria_id
        endpoint += `&maquinaria=${[maquinaria]}`;
    }

    window.location.replace(endpoint);
};

const clearItem = () => dispatch => {
    dispatch(setData({}));
    dispatch(setPage(1))
}

export const actions = {
    generarReporteKardex,
    setSelectFechaFinal,
    setSelectFechaInicial,
    filtroMantenimientoTipoChange,
    filtroMaquinariaChange,
    clearItem,
    obtener_kardex_excel,
    setTabEdit,
};

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_LISTA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_LOADER_EXCEL]: (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [SET_PARAMS_USED]: (state, { params_used }) => {
        return {
            ...state,
            params_used,
        };
    },
    [SELECT_FECHA_INICIAL]: (state, { select_fecha_inicial }) => {
        return {
            ...state,
            select_fecha_inicial,
        };
    },
    [SELECT_FECHA_FINAL]: (state, { select_fecha_final }) => {
        return {
            ...state,
            select_fecha_final,
        };
    },

    [FILTRO_TIPO_MANTENIMIENTO_ORDENES]: (state, {mantenimiento_tipo_id}) => {
        return {
            ...state,
            mantenimiento_tipo_id
        }
    },

    [FILTRO_MAQUINARIA]: (state, {maquinaria_id}) => {
        return {
            ...state,
            maquinaria_id
        }
    },

    [TAB_ORDENES_CREATE]: (state, {tab_create}) => {
        return {
            ...state,
            tab_create
        }
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    loader_excel: false,
    params_used: {},
    select_fecha_inicial: moment().startOf("month"),
    select_fecha_final: moment().endOf("month"),
    mantenimiento_tipo_id: -1,
    maquinaria_id: -1,
    tab_create:""
    
};

export default handleActions(reducers, initialState);
