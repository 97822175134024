import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/medidas/medidas';
import MedidasList from './MedidasList';

const ms2p = (state) => {
  return {
    ...state.medidas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(MedidasList);
