import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/medidas/medidas';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import MedidasCrear from './MedidasCrear';
const mstp = state => {
    return {...state.medidads}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(MedidasCrear)
