import { api } from "api";
import React, { Fragment, useState } from "react";
import Select, { Creatable, Async } from "react-select";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {unidades_medida} from '../../../../../utility/constants'


import {
    renderField,
    renderTextArea,
    renderFieldDisabled,
} from "../../../Utils/renderField/renderField";

const renderPiezas = ({
    fields,
    meta: { error, submitFailed },
    piezas,
}) => (
    <div className="col-sm-12 p-0">
        <div className="col-sm-12 form-group">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">Material</th>
                            <th scope="col" className="text-center">Medida</th>
                            <th scope="col" className="text-center">Cantidad</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {/*Contenido de la tabla*/}

            
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index} disabled>
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "33%",pointerEvents:"none" }}
                                    >
                                        <Field
                                            name={`${pieza}.nombre`}
                                            component={renderFieldDisabled}
                                            options={piezas}
                                            className="text-center"
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "33%", pointerEvents:"none"}}
                                    >
                                        <Field
                                            name={`${pieza}.medida.nombre`}
                                            component={renderFieldDisabled}
                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "33%" }}
                                    >
                                        <Field
                                            name={`${pieza}.cantidad`}
                                            type="number"
                                            component={renderFieldDisabled}
                                            decimalScale={2}
                                            parse={(val) => parseFloat(val)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


let PreparacionForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex flex-column col-12 mt-3">
                <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0 mt-3">
                        <div className="col-md-12 p-0">
                            <FieldArray
                                name="orden_material"
                                component={renderPiezas}
                            />
                        </div>
                    </div> 
                </div>

                <div className="buttons-box mt-5">
                    {/* <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link> */}

                    <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                            e.preventDefault();
                            props.history.goBack();
                    
                        }}>CANCELAR</button>
                </div>
            </div>
        </form>
    );
};

PreparacionForm = reduxForm({
    form: "OrdenForm",
})(PreparacionForm);


export default PreparacionForm;
