import { api } from "api";
import React, { Fragment, useState } from "react";
import Select, { Creatable, Async } from "react-select";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {unidades_medida} from '../../../../../utility/constants'

import "./preparacionform.css"

import {
    renderField,
    renderTextArea,
    renderFieldDisabled,
    AsyncSelectFieldTable,
    AsyncSelectField
} from "../../../Utils/renderField/renderField";

const getMedidas = (search) => {
    return api
        .get("medida", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};



const renderPiezas = ({
    fields,
    meta: { error, submitFailed },
    piezas,
    agregar,
    mensaje,
    submit_estado,
}) => (
    <div className="col-sm-12 p-0">
        <div className="">
            <button 
                type="button" 
                className="btn btn-primary btn-agregar"
                onClick={() => agregar(fields)}
                disabled={submit_estado ? submit_estado : false}
            >
                Agregar
            </button>
        </div>

        <div className="row col-12 p-0">
            { mensaje && <div className="alerta error_mensaje"> {mensaje} </div> }
        </div>
        
        <div className="col-sm-12 form-group">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">Cantidad</th>
                            <th scope="col" className="text-center">Medida</th>
                            <th scope="col" className="text-center">Material</th>
                            <th scope="col" className="text-center">Acciones</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {/*Contenido de la tabla*/}

            
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index} disabled>
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%" }}
                                    >
                                        <Field
                                            name={`${pieza}.cantidad`}
                                            type="number"
                                            component={renderFieldDisabled}
                                            decimalScale={2}
                                            parse={(val) => parseFloat(val)}
                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%", pointerEvents:"none"}}
                                    >
                                        {/* <Field
                                            name={`${pieza}.medida`}
                                            component={renderFieldDisabled}
                                        /> */}

                                        <Field
                                            valueKey="id"
                                            name={`${pieza}.medida`}
                                            labelKey="nombre"
                                            label="nombre"
                                            component={AsyncSelectField}
                                            loadOptions={getMedidas}
                                            className="form-control"
                                            disabled
                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%",pointerEvents:"none" }}
                                    >
                                        <Field
                                            name={`${pieza}.nombre`}
                                            component={renderFieldDisabled}
                                            className="text-center"
                                            
                                        />
                                    </td>
                                    
                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "25%" }}
                                    >
                                        {
                                            submit_estado ? " ":(<img
                                                src={require("assets/img/icons/borrar.png")}
                                                alt="Borrar"
                                                title="Borrar"
                                                className="action_img"
                                                onClick={() => {
                                                    fields.remove(index);
                                                }}
                                            />)
                                        }
                                        
                                    </td>

                                    <td style={{display:"none"}}>
                                   
                                        <Field
                                            name={`${pieza}.unidad`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                            options={getMedidas}

                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


let PreparacionForm = (props) => {
    const { handleSubmit, permisos, editando, submit_estado } = props;

    const [material, setMaterial] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [unidad, setUnidad] = useState(null);    
    const [mensaje, setMensaje] = useState('');


    const agregar = (fields) =>{

        if ([material, cantidad, unidad].includes('')) {
            setMensaje('Todos los campos son obligatorios')
            setTimeout(() => {
                setMensaje('')
            }, 3000);
            return
        }


        fields.push({nombre:material, medida:unidad, cantidad:cantidad})
        setMaterial('')
        setCantidad('')
        setUnidad(null)
        setMensaje('')
    };
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex flex-column col-12 mt-3">
                <div className="row col-md-12 d-flex">
                    <div className="row col-10 p-0 ml-2 mt-2">
                        <div className="col-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad" className="m-0">
                                    Cantidad
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                <input
                                    name="cantidad"
                                    type="number"
                                    className="form-control"
                                    placeholder="escriba aqui"
                                    value={cantidad}
                                    onChange={e => setCantidad(e.target.value)}
                                    min="0"
                                    disabled={submit_estado ? submit_estado : false}
                                />
                            </div>
                        </div>

                        <div className="col-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="unidad" className="m-0">
                                    Medida
                                </label>
                            </div>
                           

                            <div className="col-md-12 form-group">
                                 <Field
                                    valueKey="id"
                                    name="unidad"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectFieldTable}
                                    loadOptions={getMedidas}
                                    className="form-control"
                                    valor={unidad}
                                    value={unidad}
                                    handleChange={setUnidad}
                                    disabled={submit_estado ? submit_estado : false}

                                  
                                />
                            </div>
                        </div>

                        <div className="col-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="material" className="m-0">
                                    Material
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                <input
                                    id="material"
                                    name="material"
                                    type="text"
                                    className="form-control"
                                    placeholder="escriba aqui"
                                    value={material}
                                    onChange={e => setMaterial(e.target.value)}
                                    disabled={submit_estado ? submit_estado : false}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0 mt-3">
                        <div className="col-md-12 p-0">
                            <FieldArray
                                name="orden_material"
                                component={renderPiezas}
                                agregar={agregar}
                                mensaje={mensaje}
                                submit_estado={submit_estado}
                            />
                        </div>
                    </div> 
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link>
                    {editando ? (
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={permisos()}
                            style={{display: submit_estado ? "none" : ""}}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary">
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};

PreparacionForm = reduxForm({
    form: "OrdenForm",
})(PreparacionForm);


export default PreparacionForm;
