import React, { Component } from 'react';
import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from "react-router-dom";

import { api } from 'api';

const getProcesos = (search) => {
    return api.get('proceso', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
};

const getMantenimientoTipos = (search) => {
    return api.get('mantenimiento_tipo', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
};

export default class HeaderOrden extends Component {
    render() {
        const {
            to,
            textBtn,
            proceso,
            proceso_nombre,
            mantenimiento_tipo_id, fecha_inicial, fecha_final,
            fechaInicialChange, fechaFinalChange,
            filtroProcesoChange, filtroMantenimientoTipoChange,
            getDataOrdenes, setTab, tab, user
        } = this.props;

        const defaultOption = { id: proceso.id, nombre: proceso.nombre }; // Opción por defecto

        let orden_crear= null;
        let filtro_proceso = null;
        if (user.rol_usuario) {
            orden_crear = (user && user.rol_usuario.orden_crear);
        }
        else{
            orden_crear = true;
        }
        if (user.rol_usuario){
            if((user.rol_usuario.nombre_rol !== "Solicitante") && (user.rol_usuario.nombre_rol !== "Tecnico"))
                filtro_proceso = true
            else
                filtro_proceso = false
        }
        else{
            filtro_proceso = true
        }


        return (    
            <div className='row mb-4 ml-1 align-items-center'>
                {(fechaInicialChange !== undefined && fechaInicialChange !== null) && (
                    <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                        <div className="col-12 col-lg-12 p-0">
                            <label className="my-1 mr-2 pl-0">Fecha inicio</label>
                        </div>
                        <div className="col-12 col-lg-12 p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="DD/MM/YYYY"
                                onChange={(value) => {fechaInicialChange(value)}}
                                selected={fecha_inicial}
                            />
                        </div>
                    </div>
                )}

                {(fechaFinalChange !== undefined && fechaFinalChange !== null) && (
                    <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                        <div className="col-3 col-lg-12 p-0">
                            <label className="my-1 mr-2 pl-0">Fecha final</label>
                        </div>
                        <div className="col-12 col-lg-12 p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="DD/MM/YYYY"
                                onChange={(value) => {fechaFinalChange(value)}}
                                selected={fecha_final}
                            />
                        </div>
                    </div>
                )}
                
                {filtro_proceso &&(
                <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>


                    <div className="col-3 col-lg-12 p-0">
                        <label className="my-1 mr-2 pl-0">Proceso solicitado</label>
                    </div>

                    <div className="col-12 col-lg-12 p-0">
                        <AsyncSelect
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            isClearable
                            placeholder="Seleccionar"
                            cacheOptions
                            defaultOptions
                            loadOptions={getProcesos}
                            onChange={e => filtroProcesoChange(e ? {id:e.id, nombre:e.nombre}: {})}
                            value={defaultOption}
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['nombre'])}
                        />
                    </div>
                </div>
                )}


                <div className="col-xl-2 col-lg-3 col-md-6 my-2 " style={{borderRadius: '1em'}}>
                    <div className="col-3 col-lg-12 p-0">
                        <label className="my-1 mr-2 pl-0">Tipo de mantenimiento</label>
                    </div>
                    <div className="col-12 col-lg-12 p-0">
                        <AsyncSelect
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            isClearable
                            placeholder="Seleccionar"
                            cacheOptions
                            defaultOptions
                            loadOptions={getMantenimientoTipos}
                            onChange={e => filtroMantenimientoTipoChange(e ? e.id : -1)}
                            value={mantenimiento_tipo_id}
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['nombre'])}
                        />
                    </div>
                </div>

                <div className="col-xl-2 my-2 p-0 text-right">
                    <div className='col-lg-12'>
                        <button className="btn" onClick={() => setTab(tab)}>Filtrar</button>
                    </div>
                </div>
                {(orden_crear) &&  (
                    <div className='col-xl-2 my-2 p-0 text-right'>
                        <div className='col-lg-12'>
                                {/* <button className="btn btn-primary ml-4" onClick={() => getDataCompras && getDataCompras()}>Nueva orden de trabajo</button> */}
                                {to !== undefined && (
                                <Link to={to} className="btn btn-primary">
                                    {textBtn}
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
