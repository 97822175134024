import { handleActions } from "redux-actions";
import { api } from "api";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const DATA = "IS_DATA";
const LOADER = "LOADER_INSTALACION";
const SET_PAGE = "SET_PAGE";
const SET_LOADER_EXCEL = "SET_LOADER_EXCEL_REPORTE_INSTALACION";
const SET_PARAMS_USED = "SET_PARAMS_USED_REPORTE_INSTALACION";
const SELECT_FECHA_INICIAL = "SELECT_FECHA_INICIAL_REPORTE_INSTALACION";
const SELECT_FECHA_FINAL = "SELECT_FECHA_FINAL_REPORTE_INSTALACION";

const FILTRO_ESTADO ="FILTRO_ESTADO_INSTALACION";

const FILTRO_PROCESO = "FILTRO_PROCESO_INSTALACION";

const TAB_ORDENES_CREATE = 'TAB_ORDENES_CREATE';


const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setParamsUsed = (params_used) => ({
    type: SET_PARAMS_USED,
    params_used,
});

const setLoaderExcel = (loader_excel) => ({
    type: SET_LOADER_EXCEL,
    loader_excel,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setSelectFechaInicial = (select_fecha_inicial) => ({
    type: SELECT_FECHA_INICIAL,
    select_fecha_inicial,
});

const setSelectFechaFinal = (select_fecha_final) => ({
    type: SELECT_FECHA_FINAL,
    select_fecha_final,
});
const setPage = (page) => ({
    type: SET_PAGE,
    page,
});


const filtroEstadoChange = estado_id => (dispatch) => {
    dispatch({type: FILTRO_ESTADO, estado_id})
}


const filtroProcesoChange = proceso_id => (dispatch) => {
    dispatch({type: FILTRO_PROCESO, proceso_id})
}


export const generarReporte = (page) => (dispatch, getStore) => {

    dispatch(setLoader(true));
    //Tomamos la tada del filtro para pasarsela a los params y obtener la primera pagina
    const { select_fecha_inicial, select_fecha_final, estado_id, proceso_id } =
        getStore().instalacionR;
    const params = {};
    const params_used = {};

    // para las fechas validamos el objeto Moment
    if (select_fecha_inicial != null) {
        params.fecha_inicial = select_fecha_inicial.format("YYYY-MM-DD");
    }
    if (select_fecha_final != null) {
        params.fecha_final = select_fecha_final.format("YYYY-MM-DD");
    }

    if(estado_id > 0){
        params.estado = estado_id
    }

    if(proceso_id > 0){
        params.proceso = proceso_id
    }

    params["page"] = page || 1;
    api.get("orden/reporte_instalacion", params)
        .then((response) => {
            dispatch(setData(response));
            if (page) {
                dispatch(setPage(page));
            } else {
                dispatch(setPage(1));
            }
            dispatch(setParamsUsed(params_used));
        })
        .catch((e) => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


export const obtener_instalacion_excel = () =>  (dispatch, getStore) => {
    const resource = getStore().instalacionR;

    let endpoint = `/api/orden/descargar_reporte_instalacion/?auth_token=${localStorage.getItem("token")}`;
    dispatch(generarReporte())

    if (resource.select_fecha_inicial) {
        endpoint += `&fecha_inicial=${resource.select_fecha_inicial.format("YYYY-MM-DD")}`;
    }

    if (resource.select_fecha_final) {
        endpoint += `&fecha_final=${resource.select_fecha_final.format("YYYY-MM-DD")}`;
    }

    if (resource.estado_id > 0) {
        let estado = resource.estado_id
        endpoint += `&estado=${[estado]}`;
    }

    if (resource.proceso_id > 0) {
        let proceso = resource.proceso_id
        endpoint += `&proceso=${[proceso]}`;
    }

    window.location.replace(endpoint);
};

//Obtener los datos para la edicion cuando sea diferente a pendiente
const listar_preparacion_d = (id, estado) => (dispatch, getStore) => {
    let endpoint = "get_"+estado;
    
    dispatch(setLoader(true));
    const params = {};
    params.id_orden = id;
    
    api.get(`orden/${endpoint}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));
        dispatch(initializeForm("OrdenDetalle", response));
        dispatch(setData(response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const leer =(id, estado=false) => (dispatch) => {
    dispatch(setLoader(true));
 
    api.get(`orden/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

///______ Tab en el formulario de editar las ordenes
const setTabEdit = (id,tab_create) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_ORDENES_CREATE, tab_create})
    if (tab_create== "pendiente"){
        dispatch(leer(id,tab_create))
        return
    }

    dispatch(listar_preparacion_d(id,tab_create))
}

export const actions = {
    generarReporte,
    setSelectFechaFinal,
    setSelectFechaInicial,
    filtroEstadoChange,
    filtroProcesoChange,
    setTabEdit,
    obtener_instalacion_excel
};

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_LOADER_EXCEL]: (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [SET_PARAMS_USED]: (state, { params_used }) => {
        return {
            ...state,
            params_used,
        };
    },
    [SELECT_FECHA_INICIAL]: (state, { select_fecha_inicial }) => {
        return {
            ...state,
            select_fecha_inicial,
        };
    },
    [SELECT_FECHA_FINAL]: (state, { select_fecha_final }) => {
        return {
            ...state,
            select_fecha_final,
        };
    },

    [FILTRO_ESTADO]: (state, {estado_id}) => {
        return {
            ...state,
            estado_id
        }
    },

    [FILTRO_PROCESO]: (state, {proceso_id}) => {
        return {
            ...state,
            proceso_id
        }
    },

    [TAB_ORDENES_CREATE]: (state, {tab_create}) => {
        return {
            ...state,
            tab_create
        }
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
        finalizado: 0,
    },
    page: 1,
    loader_excel: false,
    params_used: {},
    select_fecha_inicial: moment().startOf("month"),
    select_fecha_final: moment().endOf("month"),
    estado_id: -1,
    proceso_id: -1,
    tab_create:""
};

export default handleActions(reducers, initialState);
