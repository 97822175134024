import React, { Component } from 'react';
import { Redirect } from 'react-router';
import './login.css';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import LoginForm from './LoginForm';

class Login extends Component {
    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <React.Fragment>
                <div className="bg-image"></div>
                <section className="bg-content">
                    <img src={require('assets/img/logo blanco.png')} width="15%" alt="" className="mb-1" />

                    <h4 className='mb-4'>Sistema de mantenimiento</h4>

                    <div className="col-3">
                        <LoadMask loading={loader} light>
                            
                            <LoginForm onSubmit={onSubmit} id="loginForm"/>
                            
                        </LoadMask>
                    </div>
                </section>
                
            </React.Fragment>
        )
    }
}

export default Login;