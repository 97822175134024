
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

import moment from "moment";

import {
    renderTextArea,

} from "../../../Utils/renderField/renderField";

class ProgresoForm extends Component {
    constructor(props){
        super(props)        
    }
 
    render(){
        const {handleSubmit, updateData, loader, history} =this.props;
        const options = {
            noDataText: loader ? 'Cargando...' : 'No hay datos'
        };
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container d-flex flex-column col-12  mt-3">
                    <div className="row col-md-12 d-flex ml-0">
                        <h5>Cambios realizados</h5>
                    </div>

                    <div className="mb-4">
                        <BootstrapTable 
                            data={loader ? [] : updateData.orden_bitacora_cambio} 
                            options={options} 
                        >
                            <TableHeaderColumn isKey hidden 
                                dataField="id"
                                dataAlign="center"
                            >
                                Id
                            </TableHeaderColumn>
                            <TableHeaderColumn 
                                dataField="fecha"
                                width="50%"
                                dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY HH:mm A') : '---' }
                                dataAlign="center"
                            >
                                Fecha y hora
                            </TableHeaderColumn>

                            <TableHeaderColumn 
                                dataField="nombre"
                                width="50%"
                                dataAlign="center"
                            >
                                cambios realizados
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="observacion_progreso" className="m-0">
                                Observación
                            </label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field name="observacion_progreso" component={renderTextArea} 
                            placeholder="Escriba aqui" rows={4} disabled/>
                        </div>
                    </div>

                    <div className="buttons-box mt-5">
                        <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                            e.preventDefault();
                            this.props.history.goBack();
                    
                        }}>CANCELAR</button>
                    </div>
                </div>
            </form>
        );
    }
};

ProgresoForm = reduxForm({
    form: "OrdenForm",
})(ProgresoForm);


export default ProgresoForm;
