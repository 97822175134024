import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState,
} from 'react-accessible-accordion';



const ordenes_trabajo = require("assets/img/icons/ordenestrabajo.png");
const ordenes_trabajo_activo = require("assets/img/icons/ordenestrabajoactivo.png");


const reportes = require("assets/img/icons/reportes.png");
const reportes_activo = require("assets/img/icons/reportesactivo.png");

const administracion = require("assets/img/icons/administracion.png");
const administracion_activo = require("assets/img/icons/administracionactivo.png");


const MODULO_DASHBOARD = 'DASHBOARD';
const MODULO_ADMINISTRACION = 'ADMINISTRACION';
const MODULO_ORDENES = 'ORDENES';

const MODULO_REPORTES = 'REPORTES'

class SideBar extends Component {
    state = {
        isFirstSelected: false,
        isSidebarExpanded: false,
    }

    constructor(props) {
        super(props);
    }


    mouseEnter = () => {
        this.setState({isSidebarExpanded: true})
    }
    mouseLeave = () => {
        this.setState({isSidebarExpanded: false})
    }


    isItemSelected(idModulo, path, isItemSelected) {
        // Cuando no se ha dado el primer clic en cualquier item del Sidebar, todo el 'expanded' del sidebar es false pero
        // se debe evaluar la URL para comprobar si hace math (y por lo tanto marcarla como activo).
        const isFirstSelected = this.state.isFirstSelected;
        isItemSelected && !isFirstSelected && (this.setState({isFirstSelected: true}));
        let isMatch = false;
        const isSidebarExpanded = this.state.isSidebarExpanded;
        switch (idModulo) {
            case MODULO_DASHBOARD:
                isMatch = path === '#/';
                break;
            
            case MODULO_ADMINISTRACION:
                isMatch = path.includes('/usuarios') || path.includes('/procesos') || path.includes('/maquinarias') ||  path.includes('/medidas') 
                    || path.includes('/configuracion');
                break;
            
            case MODULO_ORDENES:
                isMatch = path.includes('/ordenes') || path.includes('/mantenimiento/crear');
                break;
            
            case MODULO_REPORTES:
                isMatch = path.includes('/reportes') ||path.includes('/kardex') || path.includes('/mantenimientos') 
                    || path.includes('/instalaciones') || path.includes('/equipos');
                break;

            default:
                isMatch = false;
                break;
            }
        // La siguiente operación se sacó de un Mapa de Karnaugh que arroja como resultado:
        //     y = A'B'CD' + A'B'CD + AB'CD' + ABC'D + ABCD' + ABCD
        //     y = A'B'C + ACD' + ABD
        // donde A=isFirstSelected, B=isItemSelected, C=isMatch, D=isSidebarExpanded
        return !isFirstSelected&&!isItemSelected&&isMatch || isFirstSelected&&isMatch&&!isSidebarExpanded || isFirstSelected&&isItemSelected&&isSidebarExpanded;
    }





    render() {
        const { toggleOpen, navToggle, logOut, user } = this.props;
        let permisos = {}
        if (user.rol_usuario) {
            permisos = user.rol_usuario;
        }
        else{
            permisos.administracion = true;
            permisos.usuarios = true;
            permisos.mantenimiento = true;
            permisos.ordenes = true;
            permisos.procesos = true;
        }

        return (
            <aside className={`main-sidebar ${toggleOpen?'':'open'}`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                        <a  href="/" className="ml-2 navbar-brand" >
                            {/* <img id="main-logo"
                                 className="d-inline-block align-top mr-1"
                                 src={require('assets/img/entre_rios_isotipo.png')}
                                 alt="Logo" /> */}
                        </a>
                    </nav>
                </div>

                <div className="nav-wrapper">
                    <Accordion className="nav--no-borders flex-column nav">
                        { (permisos.mantenimiento) && (
                            <div className='nav-item'>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_ORDENES, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? ordenes_trabajo_activo: ordenes_trabajo}
                                                                className="icon-menu" alt=""/>
                                                        Mantenimiento
                                                    </div>
                                                }} 
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_ORDENES, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.ordenes) && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/ordenes" exact className="nav-link " activeClassName={'active'}
                                                            isActive={(match, location)=>location.pathname.includes('ordenes')}>
                                                        Ordenes de trabajo
                                                    </NavLink>
                                                </div>
                                                )}

                                                { (permisos.programacion) && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/mantenimiento/crear" exact className="nav-link " activeClassName={'active'}
                                                            isActive={(match, location)=>location.pathname.includes('/mantenimiento/crear')}>
                                                        Programacion mantenimientos
                                                    </NavLink>
                                                </div>
                                                )}
                                                
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}




                        
                        {/* REPORTES */}
                        
                        { (permisos.reporte) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? reportes_activo: reportes}
                                                            className="icon-menu" alt=""/>
                                                        Reportes
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.reporte_kardex) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/kardex" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('kardex')}>
                                                            Kardex
                                                        </NavLink>
                                                    </div>
                                                )}
                                                
                                                { (permisos.reporte_mantenimiento) && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/reporte/mantenimientos" exact className="nav-link " activeClassName={'active'}
                                                            isActive={(match, location)=>location.pathname.includes('mantenimientos')}>
                                                        Mantenimientos
                                                    </NavLink>
                                                </div>
                                                )}

                                                { (permisos.reporte_instalacion) && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/reporte/instalaciones" exact className="nav-link " activeClassName={'active'}
                                                            isActive={(match, location)=>
                                                                ['/reporte/instalaciones'].includes(location.pathname)}>
                                                        Instalaciones
                                                    </NavLink>
                                                </div>
                                                )}

                                                { (permisos.reporte_equipo) && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/reporte/equipos" exact className="nav-link " activeClassName={'active'}
                                                            isActive={(match, location)=>
                                                                ['/reporte/equipos'].includes(location.pathname)}
                                                                >
                                                        Equipos
                                                    </NavLink>
                                                </div>
                                                )}
                                                
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>

                        )}
                
                        { (permisos.administracion) && (

                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? administracion_activo: administracion}
                                                            className="icon-menu" alt=""/>
                                                        Administración
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                
                                                { permisos.usuarios && ( 
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/usuarios" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('/usuarios')}>
                                                            Usuarios
                                                        </NavLink>
                                                    </div>
                                                )} 
                                                { permisos.procesos && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/procesos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                            isActive={(match, location)=>location.pathname.includes('/procesos')}>
                                                        Procesos
                                                    </NavLink>
                                                </div>
                                                )} 

                                                { permisos.maquinarias && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/maquinarias" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                            isActive={(match, location)=>location.pathname.includes('/maquinarias')}>
                                                        Maquinaria
                                                    </NavLink>
                                                </div>
                                                )}


                                                
                                                { permisos.medidas && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/medidas" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                            isActive={(match, location)=>location.pathname.includes('/medidas')}>
                                                        Medida
                                                    </NavLink>
                                                </div>
                                                )}

                                                { permisos.requerimientos && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/requerimientos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                            isActive={(match, location)=>location.pathname.includes('/requerimientos')}>
                                                        Requerimientos
                                                        </NavLink>
                                                </div>
                                                )}


                                                { permisos.configuracion && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/configuracion" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                            isActive={(match, location)=>location.pathname.includes('/configuracion')}>
                                                        configuraciones
                                                    </NavLink>
                                                </div>
                                                )} 
                                                
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}

                    </Accordion>
                </div>
            </aside>
        )
    }
}

export default SideBar;