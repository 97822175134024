import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderTextArea,
} from "../../../Utils/renderField/renderField";

let MedidaForm = (props) => {
    const { handleSubmit, permisos, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12 mt-3">
                <div className="row col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">
                                Nombre
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

               
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/medidas">
                        CANCELAR
                    </Link>
                    {editando ? (
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={permisos()}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary">
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};
MedidaForm = reduxForm({
    form: "MedidaForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido."),
        });
    },
})(MedidaForm);

export default MedidaForm;
