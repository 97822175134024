import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

const vistas = [
    {value: '/', label: 'Dashboard'},

]

let RolForm = props => {
    const { handleSubmit, permisos, editando } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-4 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_rol" className="m-0">NOMBRE DEL ROL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="nombre_rol" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <CardEmpty noShadow noPadding>
                                <div className="p-1 mt-1 mr-2">
                                    <label>ADMINISTRACIÓN</label>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Roles</label>
                                        <Field component={renderFieldCheck} className="form-control" name="roles" />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-start" style={{marginBottom: '0.1rem'}}>
                                        <label className="gris">Usuarios</label>
                                        <Field component={renderFieldCheck} className="form-control" name="usuarios" />
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mt-2">
                            <div>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>PROCESOS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear procesos</label>
                                            <Field component={renderFieldCheck} className="form-control" name="recepciones_activas" />
                                        </div>
                                        {/* <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Finalizadas</label>
                                            <Field component={renderFieldCheck} className="form-control" name="recepciones_finalizadas" />
                                        </div> */}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2">
                                        <label>MAQUINARIAS</label>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <label className="gris">Crear maquinarias</label>
                                            <Field component={renderFieldCheck} className="form-control" name="despachos" />
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>

                            <div style={{marginTop: '1.112rem'}}>
                                <CardEmpty noShadow noPadding>
                                    <div className="p-1 mt-1 mr-2" style={{height: "95.41px"}}>
                                        <div className="d-flex flex-column justify-content-start">
                                            <label>INICIAR EN PANTALLA</label>
                                            <Field component={SelectField} className="form-control" name="vista_inicial" options={vistas}/>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/roles">CANCELAR</Link>
                    {editando ?
                        (<button type="submit" className="btn btn-primary" onClick={permisos()}>GUARDAR</button>)
                        :
                        (<button type="submit" className="btn btn-primary">GUARDAR</button>)
                    }
                </div>
            </div>
        </form>
    )
};
RolForm = reduxForm({
    form: 'RolForm',
    validate: data => {
        return validate(data, {
            'nombre_rol': validators.exists()('Campo requerido.'),
        })
    }
})(RolForm);

export default RolForm
