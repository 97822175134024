import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, {TabPane} from "rc-tabs";

import { HeaderOrden } from '../../../Utils/Grid/Headers';

import OrdenList from './OrdenesList';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
// import 'react-tabs/style/react-tabs.css'
import './MyTabs.css'

export default class OrdenesList extends Component{
    async componentDidMount() {
        const { listar, page_finalizadas, filtroProcesoChange,user } = this.props;
        await this.cargarDatos(listar, page_finalizadas, filtroProcesoChange,user);
    }

    async cargarDatos(listar, page_finalizadas, filtroProcesoChange,user) {
        const procesoId = user.proceso.id
        const procesoNombre = user.proceso.nombre
        
        const proceso = {
            id:procesoId,
            nombre: procesoNombre,
        }

        const nombre_rol = user.rol_usuario ? user.rol_usuario.nombre_rol: "Administrador"

        if(nombre_rol === "Administrador")
            await filtroProcesoChange(proceso);
        
        await listar(page_finalizadas);
    }

    render() {
        const { setTab, tab } = this.props;
        const { data, loader } = this.props;
        const {
            fecha_inicio, fechaInicio,
            fecha_final, fechaFinal,
            filtroProcesoChange, filtroMantenimientoTipoChange,
            listar, filtro_orden, user
        } = this.props;

       
        return(
            <div className="">
                <Card
                    icon="ordentrabajomenu"
                    titulo="Órdenes de trabajo"
                    subtitulo=""
                    noBorder
                >   
                    <HeaderOrden to="orden/crear" textBtn="Nueva orden de trabajo" 
                        fechaInicialChange={fechaInicio} fecha_inicial={fecha_inicio} 
                        fechaFinalChange={fechaFinal} fecha_final={fecha_final}
                        filtroProcesoChange={filtroProcesoChange} filtroMantenimientoTipoChange={filtroMantenimientoTipoChange}
                        getDataOrdenes={filtro_orden} ///Manda el metodo listar
                        setTab={setTab} 
                        tab={tab}
                        user={user}
                        {...this.props} 
                    />


                    <Tabs
                        activeKey={tab}
                        tabBarPosition="top"
                        onChange={tab =>{setTab(tab)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="Todas" key="TODAS" >
                            
                        </TabPane>
                        <TabPane tab="General" key="PENDIENTE"  style={{ background: '#000 !important' }}>

                        </TabPane>
                        <TabPane tab="Revisión" key="REVISION" >
                            
                        </TabPane>
                        <TabPane tab="Preparación" key="PREPARACION" >
                            
                        </TabPane>
                        <TabPane tab="Progreso" key="PROGRESO" >
                           
                        </TabPane>
                        <TabPane tab="Validación" key="VALIDACION" >
                            
                        </TabPane>
                        <TabPane tab="Finalizado" key="FINALIZADO" className="tab1">
                            
                        </TabPane>
                        
                    </Tabs>

                    <CardEmpty noShadow>
                        <OrdenList {...this.props}/>
                    </CardEmpty>

                </Card>
            </div>
        )
    }
}
