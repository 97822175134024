import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/maquinarias/maquinarias';
import MaquinariasList from './MaquinariasList';


const ms2p = (state) => {
  return {
    ...state.maquinarias,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(MaquinariasList);
