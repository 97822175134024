import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './acciones.css';
import Swal from "sweetalert2";

import moment from "moment";
const ver_img = require("assets/img/icons/ver.png");
const editar_img = require("assets/img/icons/editar.png");
const alerta = require("assets/img/icons/alerta_roja.png");
const eliminar_orden = require("assets/img/icons/ordendelete.png");
const accion = require("assets/img/icons/ordenaccion.png");
class Acciones extends Component {
    constructor(props) {
        super(props);

        this.setTabEdit = this.setTabEdit.bind(this);
        this.calculardias = this.calculardias.bind(this);
    }

    
    setTabEdit = (id,tab) => {
        return () => {
            this.props.setTabEdit(id,tab)
        };
    }

    calculardias = (fecha_inicio, fecha_final) =>{
        if(fecha_inicio === null || fecha_final === null){
            return "---"
        }

        let fecha1 = new Date(fecha_final);
        let fecha2 = new Date(fecha_inicio);

        // Conocer si la fecha actual es mayor a la fecha de ejecucion
        // Para que empiece a reducir la cantidad de dias
        let fechaActual = moment()

        if(fechaActual >= fecha2)
            fecha2 = new Date(moment())

        // Calcular la diferencia en milisegundos
        let diferenciaMilisegundos = fecha1.getTime() - fecha2.getTime();

        // Convertir la diferencia a días
        let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
        diferenciaDias = diferenciaDias + 1;
        return(diferenciaDias)
    }


    render() {
        const { id, row, ver} = this.props;
        
        return (
            <div className="d-flex justify-content-center">
                {ver !== undefined && typeof ver === "string" && row.estado !==6 &&(
                    this.calculardias(row.fecha_ejecucion, row.fecha_finalizacion)
                    
                )}
            </div>
        );
    }
}

Acciones.propTypes = {};

export function OrdenDias(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} row={row} {...acciones} />;
    };
}

