import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "medidas",
    "medida",
    "MedidaForm",
    "/medidas",
);

export default handleActions(reducers, initialState);
