import { api } from "api";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderField,
    renderSwitch,
    SelectField,
    AsyncSelectField,
    renderDatePicker,
} from "../../../Utils/renderField/renderField";
import Alerta from "Utils/Alerta/Alerta";
import { roles } from "../../../../../utility/constants";

const getRoles = (search) => {
    return api
        .get("rol", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};


const getProceso = (search) => {
    return api
        .get("proceso", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

/**
 * Obtiene el listado de Acopios activos desde la API.
 * @param search campo de búsqueda (opcional)
 */


let UsuarioForm = (props) => {
    const { handleSubmit, permisos, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
                <Alerta texto="Se enviará por e-mail la contraseña temporal para el nuevo usuario." />
            </div>
            <div className="form-group grid-container">
                <div className="form-group grid-container d-flex align-items-center flex-column">
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">
                                    Nombre
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="apellido" className="m-0">
                                    Apellido
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="apellido"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label
                                    htmlFor="fecha_nacimiento"
                                    className="m-0"
                                >
                                    Fecha de nacimiento
                                </label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    name="fecha_nacimiento"
                                    component={renderDatePicker}
                                    todayButton="Hoy"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="dpi" className="m-0">
                                    DPI
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="dpi"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    maxLength={13}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label
                                    htmlFor="codigo_empleado"
                                    className="m-0"
                                >
                                    Codigo de empleado
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="codigo_empleado"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="rol" className="m-0">
                                    Rol
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="rol_usuario"
                                    labelKey="nombre_rol"
                                    label="nombre_rol"
                                    component={AsyncSelectField}
                                    loadOptions={getRoles}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="username" className="m-0">
                                    Nombre de usuario
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="username"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="email" className="m-0">
                                    Correo electronico
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="ubicacion" className="m-0">
                                    Proceso
                                </label>
                            </div>

                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="proceso"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getProceso}
                                    className="form-control"
                                />
                            </div>
                            
                        </div>

                        {!props.editando && (
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="password" className="m-0">
                                        Contraseña
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="password"
                                        component={renderField}
                                        type="password"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {props.editando && (
                        <div className="row col-12 p-0">
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="row">
                                    <div className="col-md-8 text-gris">
                                        <label htmlFor="activo" className="m-0">
                                            USUARIO ACTIVO
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <Field
                                            name="activo"
                                            component={renderSwitch}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 ">
                                <div className="col-md-12 col-sm-12">
                                    <label
                                        htmlFor="new_password"
                                        className="m-0"
                                    >
                                        NUEVA CONTRASEÑA
                                    </label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="new_password"
                                        component={renderField}
                                        type="password"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="buttons-box mt-5">
                        <Link
                            className="btn btn-outline-dark mr-5"
                            to="/usuarios"
                        >
                            CANCELAR
                        </Link>
                        {editando ? (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={permisos()}
                            >
                                GUARDAR
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                GUARDAR
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};
UsuarioForm = reduxForm({
    form: "EditarUsuarioForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido."),
            apellido: validators.exists()("Campo requerido."),
            rol_usuario: validators.exists()("Campo requerido."),
            telefono: validators.exists()("Campo requerido."),
            username: validators.exists()("Campo requerido."),
            password: validators.exists()("Campo requerido."),
            fecha_nacimiento:validators.exists()("Campo requerido."),
            dpi:validators.exists()("Campo requerido."),
            codigo_empleado:validators.exists()("Campo requerido."),
            email:validators.exists()("Campo requerido."),
            proceso:validators.exists()("Campo requerido."),
        });
    },
})(UsuarioForm);
const selector = formValueSelector("EditarUsuarioForm");
UsuarioForm = connect((state) => {
    const a = "test";
    return {
        a,
    };
})(UsuarioForm);
export const UsuarioUpdateForm = reduxForm({
    form: "EditarUsuarioForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido."),
            apellido: validators.exists()("Campo requerido."),
            rol_usuario: validators.exists()("Campo requerido."),
            username: validators.exists()("Campo requerido."),
            password: validators.exists()("Campo requerido."),
            fecha_nacimiento:validators.exists()("Campo requerido."),
            proceso:validators.exists()("Campo requerido."),
        });
    },
    asyncBlurFields: [],
})(UsuarioForm);

export default UsuarioForm;
