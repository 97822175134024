import React from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import {renderField} from '../../Utils/renderField/renderField';


let ConfiguracionForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit} className='mt-4'>
            <div className="form-group grid-container">
                <div className="">
                    <div className="col-12 d-flex justify-content-between">
                        <h6 className="mb-3">Jefe de proceso</h6>
                    </div>
                    
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Correo jefe de proceso</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                 
                <div className="buttons-box mt-5">
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
ConfiguracionForm = reduxForm({
    form: 'ConfiguracionForm',
    validate: data => {
        return validate(data, {
            'email': validators.exists()('Campo requerido.'),
        })
    }
})(ConfiguracionForm);



export default ConfiguracionForm
