import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"
import { isNull } from 'lodash';

export default class UsuariosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        
        const showRol = (data) => {
            if (data === null)
                return ""
            return data.nombre_rol;
        };

        
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="Usuarios"
                    subtitulo="">
                    <Header to="usuario/crear" textBtn="CREAR USUARIO" buscar="si" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            isKey
                            dataField="username"
                            dataSort
                        >
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_completo"
                            dataSort
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="rol_usuario"
                            dataSort
                            dataFormat={showRol}
                        >
                            Rol
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proceso"
                            dataSort
                            dataFormat={(cell) => cell ? cell.nombre : " "}

                        >
                            Ubicación
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "usuario", eliminar})}
                        >
                            Acciones
                        </TableHeaderColumn>
                        
                    </Grid>
                </Card>
            </div>
        )
    }
}
