import React, { Component } from "react";
import CardForm from "Utils/Cards/CardForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import Form from "./MantenimientosForm";
import MantenimientoForm from "./MantenimientosForm";
import { getMe } from "../../../../../redux/modules/cuenta/login";

export default class MantenimientoCrear extends Component {

    render() {
        const { crear } = this.props;
        const { loader } = this.props;
        
        
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="administracionactivo"
                    titulo="Programación de mantenimientos"
                    subtitulo=""
                    col="12"
                >
                    <LoadMask loading={loader} blur>
                        <Form onSubmit={crear}
                            obtener_gantt_data={this.props.obtener_gantt_data}
                            obtener_gantt_pdf={this.props.obtener_gantt_pdf}
                            obtener_gantt_excel={this.props.obtener_gantt_excel}
                            loader={loader}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        );
    }
}
