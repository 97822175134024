import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './acciones.css';
import Swal from "sweetalert2";

const eliminar_img = require("assets/img/icons/borrar.png");
const ver_img = require("assets/img/icons/ver.png");
const editar_img = require("assets/img/icons/editar.png");
const alerta = require("assets/img/icons/alerta_roja.png");
const eliminar_orden = require("assets/img/icons/ordendelete.png");
const accion = require("assets/img/icons/ordenaccion.png");
class Acciones extends Component {
    constructor(props) {
        super(props);
        this.editar = this.editar.bind(this);
        this.ver = this.ver.bind(this);
    }

    eliminar = (id) => {
        return () => {
            const SwalMod = Swal.mixin({
                customClass: {
                    confirmButton: "btn-swal btn-primary-swal",
                    cancelButton: "btn btn-outline-dark mr-5",
                    //   content: 'd-flex justify-content-center mb-4',
                    //   title: 'verde-secundario',
                    //   image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            });
            SwalMod.fire({
                title: "¿ELIMINAR?",
                text: "Esta acción no se puede revertir",
                imageUrl: alerta,
                imageAlt: "Alerta",
                showCancelButton: true,
                confirmButtonText: "ELIMINAR",
                cancelButtonText: "CANCELAR",
                reverseButtons: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    editar(id, row) {
        return () => {
            this.props.editar(id, row);
        };
    }

    ver(id, row) {
        return () => {
            this.props.ver(id, row);
        };
    }

    updateEstado = (id) => {
        return () => {
            const SwalMod = Swal.mixin({
                customClass: {
                    confirmButton: "btn-swal btn-primary",
                    cancelButton: "btn btn-outline-dark mr-5",
                    //   content: 'd-flex justify-content-center mb-4',
                    //   title: 'verde-secundario',
                    //   image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            });
            SwalMod.fire({
                title: "¿Actualizar estado?",
                text: "Esta acción no se puede revertir",
                imageUrl: alerta,
                imageAlt: "Alerta",
                showCancelButton: true,
                confirmButtonText: "ACEPTAR",
                cancelButtonText: "CANCELAR",
                reverseButtons: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.value) {
                    this.props.updateEstado(id);
                }
            });
        };
    };


    render() {
        const { id, row, ver, editar, eliminar, eliminarOrden, acciones, updateEstado,editarOrden } = this.props;
        
        return (
            <div className="d-flex justify-content-center">
                {ver !== undefined && typeof ver === "string" && (
                    <Link to={`${ver}/${id}/`} className="px-2">
                        <img src={ver_img} alt="Ver" className="action_img" />
                    </Link>
                )}
                {ver !== undefined && typeof ver !== "string" && (
                    <a className="text-warning" onClick={this.editar(id, row)}>
                        <img
                            src={ver_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </a>
                )}

                {editar !== undefined && typeof editar === "string" && (
                    <Link
                        className="text-warning"
                        to={`${editar}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )}
                {editar !== undefined && typeof editar !== "string" && (
                    <a className="text-warning" onClick={this.editar(id, row)}>
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </a>
                )}
                {updateEstado !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.updateEstado(id)}
                    >
                        <img
                            src={accion}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}
                {eliminar !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminar(id)}
                    >
                        <img
                            src={eliminar_img}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}

                {editarOrden !== undefined && typeof editarOrden === "string" && (
                    <Link
                        className="text-warning"
                        to={`${editarOrden}/${row.estado}/${id}/editar`}
                    >
                        <img
                            src={editar_img}
                            alt="Editar"
                            className="action_img"
                        />
                    </Link>
                )}

                {eliminarOrden !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminar(id)}
                    >
                        <img
                            src={eliminar_orden}
                            alt="Eliminar"
                            className="action_img"
                        />
                    </a>
                )}

                
            </div>
        );
    }
}

Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} row={row} {...acciones} />;
    };
}

export function standardActionsMaquinaria(acciones) {
    return (cell, row) => {
        if (row.estado == 1) {
            return <Acciones id={cell} row={row} {...acciones} />;
        }
    };
}
