import React, { Component } from "react";
import CardEmpty from "../../../Utils/Cards/CardEmpty"
import LoadMask from "../../../Utils/LoadMask/LoadMask"
import Card from "../../../Utils/Cards/Card";
import Filtros from "./filtro";
import TablaKardex from "./TablaKardex";

class Kardex_Reporte extends Component {
  
  

  render(){
    const {loader} = this.props;
    return(
      <Card
        icon="reportesactivo"
        titulo="Kardex"
      >
        <LoadMask loading={loader} blur>
          <Filtros {...this.props}/>
          <CardEmpty>
            <h5>Listado de mantenimientos</h5>
            <TablaKardex {...this.props}/>
          </CardEmpty>
            
        </LoadMask>
      </Card>
    )
  }
  
    
}

export default Kardex_Reporte;