import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ordenes/ordenes';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import OrdenDetalle from './OrdenDetalle';

const mstp = state => {
    return {...state.ordenes}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(OrdenDetalle)
