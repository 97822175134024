import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ordenes/ordenes';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import OrdenesCrear from './OrdenesCrear';

const mstp = state => {
    const user = state.login.me;

    return {...state.ordenes, user}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(OrdenesCrear)
